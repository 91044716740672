import { Link } from 'gatsby';
import React from 'react';

const Lesson = () => {
  return (
    <>
      <h1>Lekcja</h1>
      <Link to="/">Powrót</Link>
    </>
  );
};

export default Lesson;
